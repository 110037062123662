export class UrlTools {
  /**
   *
   * @param str
   * @returns {boolean}
   */
  isURL (str) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
    return pattern.test(str)
  }

  /**
   *
   * @param str
   * @returns {boolean}
   */
  hasHttpPrefix (str) {
    const pattern = new RegExp('^(http|https)://')

    return pattern.test(str)
  }

  /**
   *
   * @param str
   * @returns {boolean}
   */
  lastCharIsSlash (str) {
    const lastChar = str.substr(str.length - 1, str.length)

    if (lastChar === '/') {
      return true
    }

    return false
  }

  /**
   *
   * @param value
   * @param url
   * @param changeEmpty
   * @returns {*}
   */
  swapUrl (value, url, changeEmpty = false) {
    if ((url === '' || url === null)) {
      return value
    }

    if (value === null || changeEmpty) {
      return url
    }

    const splittedField = value.split('/')
    let sliceCount = 1

    if (this.hasHttpPrefix(value)) {
      sliceCount = 3
    }

    const suffixArray = splittedField.slice(sliceCount, splittedField.length)
    const suffix = suffixArray.join('/')

    if (!this.isURL(value)) {
      return url
    }

    if (!this.lastCharIsSlash(url)) {
      url = url + '/'
    }

    if (this.lastCharIsSlash(url)) {
      url = url.slice(0, url.length - 1)
    }

    if (suffix === '') {
      return url
    }

    return url + '/' + suffix
  }

  hasOnlyBase (geos, baseUrl) {
    for (const geo of geos) {
      if (geo[0].url === baseUrl || geo[0].url === baseUrl + '/') {
        return true
      }
    }

    return false
  }

  /**
   *
   * @param url
   */
  isDomainOnly (url) {
    if (url === null || url === undefined) {
      url = ''
    }

    return (url.match(/\//g) || []).length <= 2
  }
}
