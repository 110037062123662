




















import { Vue, Component, Prop } from 'vue-property-decorator'
import { UrlTools } from '@/classes/UrlTools'
import { Modal, Button } from '../../../lib/mavejs'
import { VideoCampaign } from '@/interfaces/form/VideoCampaign'

@Component({ components: { Modal, Button } })
export default class UrlCheck extends Vue {
  @Prop() model!: VideoCampaign;

  $refs: {
    templateModal: Modal;
  }

  handleSubmit () {
    const url = new UrlTools()

    for (const ad of this.model.ads) {
      if (url.isDomainOnly(ad.url)) {
        this.$refs.templateModal.isOpen = true
        return
      }
    }

    this.$emit('submit')
  }
}
