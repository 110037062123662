import { Http } from '../../lib/mavejs'

export class Youtube {
  url: string
  isSuccessful: boolean
  isUrl: boolean

  http = new Http({
    success: () => {
      this.isSuccessful = true
    },
    failed: () => {
      this.isSuccessful = false
    },
    unauthorized: () => {
      this.isSuccessful = false
    }
  })

  constructor (url) {
    this.url = url
  }

  isValid () {
    const codeMatcher = /^[a-zA-Z0-9_-]{11}?$/
    const urlMatcher = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/
    if (codeMatcher.test(this.url)) {
      this.isUrl = false
      return true
    }

    if (urlMatcher.test(this.url)) {
      this.isUrl = true
      return true
    }

    return false
  }

  buildPageUrl (url) {
    const chunks = url.split('/')
    let id = ''

    if (!this.isUrl) {
      return 'https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&id=' + url + '&key=AIzaSyDhcP9NgeJGUr4mTsK58I4fOZoz7XWTPnQ'
    }

    for (let index = 0; index < chunks.length; index++) {
      if (index === 3) {
        chunks[index] = chunks[index].replace('watch?v=', '')
        id += chunks[index]
        return 'https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&id=' + id + '&key=AIzaSyDhcP9NgeJGUr4mTsK58I4fOZoz7XWTPnQ'
      }
    }

    return ''
  }

  getTitle () {
    const pageUrl = this.buildPageUrl(this.url)
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }

    return fetch(pageUrl, { method: 'GET', headers: headers })
      .then((response) => response.json())
      .then((data: any) => {
        if (data.items !== undefined && data.items.length > 0) {
          return data.items[0].snippet.title
        } else if (data.error !== undefined) {
          return { error: true }
        } else {
          return ''
        }
      })
  }
}
