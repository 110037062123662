export class Obfuscator {
  mapping = {
    a: 'а',
    b: 'ƅ',
    c: 'с',
    e: 'е',
    f: 'f',
    g: 'g',
    h: 'h',
    i: 'і',
    j: 'ј',
    k: 'k',
    l: 'I',
    m: 'm',
    n: 'n',
    o: 'о',
    p: 'р',
    q: 'q',
    r: 'r',
    s: 'ѕ',
    t: 't',
    u: 'u',
    v: 'ν',
    w: 'w',
    x: 'x',
    y: 'у',
    z: 'z',
    A: 'А',
    B: 'В',
    C: 'С',
    D: 'D',
    E: 'Е',
    F: 'Ϝ',
    G: 'G',
    H: 'Н',
    I: 'І',
    J: 'J',
    K: 'Κ',
    L: 'L',
    M: 'М',
    N: 'Ν',
    O: 'О',
    P: 'Р',
    Q: 'Q',
    R: 'R',
    S: 'Ѕ',
    T: 'Т',
    U: 'U',
    V: 'V',
    W: 'W',
    X: 'Х',
    Y: 'Υ',
    Z: 'Ζ'
  };

  text: string;

  constructor (text) {
    this.text = text
  }

  obfuscate (): string {
    const letters = this.text.split('')
    let obfuscatedText = ''

    for (const letter of letters) {
      obfuscatedText += (this.mapping[letter] !== undefined) ? this.mapping[letter] : letter
    }

    return obfuscatedText
  }
}
