import { OverwriteCampaign } from '@/interfaces/models/OverwriteCampaign'
import { OverwriteAdgroup } from '@/interfaces/models/OverwriteAdgroup'

export class AdForm {
  // eslint-disable-next-line no-use-before-define
  geos: Array<AdGeoForm> = [];
}

export interface AdGeoFormItem {
  name: string;
  video: string;
  description: string;
  longHeadline: string;
   keywordPositive: string;
   keywordNegative: string;
   keywordPositiveMatch: string;
   keywordNegativeMatch: string;
}

export class AdGeoForm {
  public id = 0
  public url = ''
  public description = ''
  public longHeadline = ''
  public ads: Array<AdGeoFormItem> = []
  public keywordPositive: string
  public keywordNegative: string
  public keywordPositiveMatch: string
  public keywordNegativeMatch: string

  constructor (geoId, url = '', description = '', longHeadline = '', keywordPositive = '', keywordNegative = '', keywordPositiveMatch = '', keywordNegativeMatch = '') {
    this.id = geoId
    this.url = url
    this.description = description
    this.longHeadline = longHeadline
    this.keywordPositive = keywordPositive
    this.keywordNegative = keywordNegative
    this.keywordPositiveMatch = keywordPositiveMatch
    this.keywordNegativeMatch = keywordNegativeMatch
  }
}
