






import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Textbox, Button, SubTitle } from '../../../lib/mavejs'
import { AdData } from '@/classes/ads/AdData'

@Component({ components: { Textbox, Button, SubTitle } })
export default class AdsTemplate extends Vue {
  @Prop() amount!: number;

  template = new AdData(this.amount).template();
}

