import { Ad } from '@/interfaces/models/Ad'
import { Geo } from '@/interfaces/models/Geo'
import { AdTemplate } from '@/interfaces/AdTemplate'
import { AdForm, AdGeoForm } from '@/interfaces/AdForm'
import { alphabetical } from '@/functions'

export class AdData {
  geos: Array<Geo>
  amount: number

  ads: Array<Ad>
  name: string
  isGlobal: boolean
  countriesWithLanguages: Array<string>
  id: number

  constructor (amount, geos = null, name = null, isGlobal = null, countriesWithLanguage = null, id = null) {
    this.amount = amount
    this.geos = alphabetical(geos, 'extra')
    this.name = name
    this.isGlobal = isGlobal
    this.countriesWithLanguages = countriesWithLanguage
    this.id = id
  }

  template (): AdTemplate {
    const template = { id: this.id, name: this.name, isGlobal: this.isGlobal, ads: [], description: '', longHeadline: '', url: '', countriesWithLanguage: this.countriesWithLanguages, keywordPositive: '', keywordNegative: '', keywordPositiveMatch: 'Phrase', keywordNegativeMatch: 'Phrase' }

    for (let index = 0; index < this.amount; index++) {
      template.ads[index] = { name: '', video: '', description: '', longHeadline: '', keywordPositive: '', keywordNegative: '', keywordPositiveMatch: 'Phrase', keywordNegativeMatch: 'Phrase' }
    }

    return Object.assign({}, template)
  }

  form (ads: Array<Ad> = null): AdForm {
    if (ads !== null) {
      return this.reverse(ads)
    }

    const form = new AdForm()
    let geoIndex = 0

    for (const geo of this.geos) {
      form.geos[geoIndex] = new AdGeoForm(geo.id)

      for (let adIndex = 0; adIndex < this.amount; adIndex++) {
        form.geos[geoIndex].ads[adIndex] = { description: '', longHeadline: '', name: '', video: '', keywordPositive: '', keywordNegative: '', keywordPositiveMatch: 'Phrase', keywordNegativeMatch: 'Phrase' }
      }

      geoIndex++
    }

    return form
  }

  transformFilled (form: AdForm, ad, geo): Array<Ad> {
    const ads = []
    const data = ad.video
    if (data !== undefined) {
      let saveDescription = ad.description
      let saveLongHeadLine = ad.longHeadline
      if (ad.description === '') {
        saveDescription = geo.description
      } else if (ad.longHeadline === '') {
        saveLongHeadLine = geo.longHeadline
      }
      ads.push({
        name: ad.name,
        video: ad.video,
        geo: geo.id,
        url: geo.url,
        description: saveDescription,
        longHeadline: saveLongHeadLine,
        keywordPositive: ad.keywordPositive,
        keywordNegative: ad.keywordNegative,
        keywordPositiveMatch: ad.keywordPositiveMatch,
        keywordNegativeMatch: ad.keywordNegativeMatch
      })
    }
    return ads
  }

  transform (form: AdForm, ad, geo): Array<Ad> {
    const ads = []
    const data = ad.video
    if (data !== undefined) {
      ads.push({
        name: ad.name,
        video: ad.video,
        geo: geo.id,
        url: geo.url,
        description: geo.description,
        longHeadline: geo.longHeadline,
        keywordPositive: ad.keywordPositive,
        keywordNegative: ad.keywordNegative,
        keywordPositiveMatch: ad.keywordPositiveMatch,
        keywordNegativeMatch: ad.keywordNegativeMatch
      })
    }
    return ads
  }

  makeAds (geo: Geo): Array<Ad> {
    const ads = []

    for (let adIndex = 0; adIndex < this.amount; adIndex++) {
      ads.push({
        name: '',
        video: '',
        geo: geo.id,
        url: geo.url,
        description: '',
        longHeadline: '',
        keywordPositive: '',
        keywordNegative: '',
        keywordPositiveMatch: '',
        keywordNegativeMatch: ''
      })
    }

    return ads
  }

  addNewAds (oldAds: Array<Ad>, geo: Geo): Array<Ad> {
    const ads = oldAds.filter(ca => ca.geo === geo.id)
    const oldAmount = (ads.length / this.geos.length)

    for (let adIndex = 0; adIndex < (this.amount - oldAmount); adIndex++) {
      ads.push({
        name: '',
        video: '',
        geo: geo.id,
        url: geo.url,
        description: '',
        longHeadline: '',
        keywordPositive: '',
        keywordNegative: '',
        keywordPositiveMatch: '',
        keywordNegativeMatch: ''
      })
    }

    return ads
  }

  reverse (ads: Array<Ad>): AdForm {
    const form = new AdForm()
    let geoIndex = 0

    for (const geo of this.geos) {
      let geoAds = ads.filter(ca => ca.geo === geo.id)

      if (geoAds[0] === undefined) {
        ads = ads.concat(this.makeAds(geo))
        geoAds = ads.filter(ca => ca.geo === geo.id)
      }

      if (geoAds.length < this.amount) {
        geoAds = this.addNewAds(ads, geo)
      }

      if (geoAds.length > this.amount) {
        geoAds = geoAds.filter((ad, index) => index <= (this.amount - 1))
      }

      const url = geoAds[0].url
      const description = geoAds[0].description
      const longHeadline = geoAds[0].longHeadline
      const keywordPositive = geoAds[0].keywordPositive
      const keywordNegative = geoAds[0].keywordNegative
      const keywordPositiveMatch = geoAds[0].keywordPositiveMatch
      const keywordNegativeMatch = geoAds[0].keywordNegativeMatch
      const OC = []

      form.geos[geoIndex] = new AdGeoForm(geo.id, url, description, longHeadline, keywordPositive, keywordNegative, keywordPositiveMatch, keywordNegativeMatch)

      let adIndex = 0

      for (const ad of geoAds) {
        form.geos[geoIndex].ads[adIndex] = { name: ad.name, video: ad.video, description: ad.description, longHeadline: ad.longHeadline, keywordPositive: ad.keywordPositive, keywordNegative: ad.keywordNegative, keywordPositiveMatch: ad.keywordPositiveMatch, keywordNegativeMatch: ad.keywordNegativeMatch }
        adIndex++
      }

      geoIndex++
    }

    return form
  }
}
